import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import companyServices from '../../../api/services/companyServices';
import { Modal } from 'react-bootstrap';
import Lookup from '../lookup/Lookup';
import { useRecoilState } from 'recoil';
import { companiesAtom } from '../../../atoms/companiesAtom';
import { PulseLoader } from 'react-spinners';

const DgCompany = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useRecoilState(companiesAtom);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    refreshLocalSelectedCompanies();
  }, [companies]);

  const loadCompanies = () => {
    setIsLoading(true);
    companyServices.getCompanies().then(res => {
      if (res?.data) {
        const filteredCompanies = [...res.data].filter(company => company.active);
        const localCompanies = [...filteredCompanies.map(c => {
          return { idCompanyErp: c.idCompanyErp, name: `${c.idCompanyErp} - ${c.name}` };
        })];
        setCompanies([...localCompanies]);
        loadSelectedCompanies();
        setIsLoading(false);
      }
    });
  };

  const refreshLocalSelectedCompanies = () => {
    const localSelectedCompanies = [];
    selectedValues.forEach(s => {
      const company = companies.find(c => c.idCompanyErp === s);
      if (company) {
        localSelectedCompanies.push(structuredClone(company));
      }
    });
    setSelectedCompanies(localSelectedCompanies);
  };

  const loadSelectedCompanies = () => {
    const localSelectedValues = selectedCompanies.map(c => c.idCompanyErp);
    setSelectedValues(localSelectedValues);
  };

  const confirm = () => {
    refreshLocalSelectedCompanies();
    close();
  };

  const close = () => {
    props.methods.onClose();
  };

  const setSelectedValuesDdl = values => {
    const localSelectedValues = values.map(v => v.value);
    setSelectedValues(localSelectedValues);
  };

  const selectAll = () => {
    const localSelectedValues = companies.map(c => c.idCompanyErp);
    setSelectedValues(localSelectedValues);
  };

  const getContent = () => {
    if (isLoading) {
      return <PulseLoader data-cy="dgCompanyLoader" size={10} color={'#333'} loading={isLoading} />;
    }
    const placeholder = 'adicione uma empresa';
    const component =
      <>
        <Lookup
          options={companies}
          fieldLabel={'name'}
          fieldValue={'idCompanyErp'}
          setSelected={setSelectedValuesDdl}
          selectValues={selectedValues}
          placeholder={placeholder}
          newVersion={true} />
        <button onClick={selectAll} className="btn btn-success">Todas</button>
      </>;
    return component;
  };


  return (
    <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-lg" onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">Selecione as empresas que deseja visualizar</Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <div className="row">
          <div className="col-md-10">
            {getContent()}
          </div>
        </div>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <div>
          <button data-cy="confirmSelectCompanies" onClick={confirm} className="btn btn-success">Confirmar</button>
          <button onClick={close} className="btn btn-dark">Cancelar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DgCompany.propTypes = {
  methods: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default DgCompany;
